/* Inicio.css */

/* Estilos generales */
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  box-sizing: border-box;
}

.main-container {
  text-align: center; /* Centrar el texto */
  padding: 20px;
  max-width: 60%; /* Limitar el ancho para que el texto no sea demasiado largo */
  margin: 0 auto; /* Centrar el contenedor en la página */
}

h1 {
  font-size: 2rem;
  margin-bottom: 20px;
}

p {
  line-height: 1.6;
  margin-bottom: 15px;
}

/* Estilo de los botones */
.button-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Permite que los botones se ajusten a múltiples líneas si es necesario */
  margin: 20px 0;
}

.button {
  background-color: #4a90e2; /* Azul */
  color: white;
  border: none;
  padding: 10px 20px;
  text-decoration: none;
  margin: 5px; /* Ajustado para mejor espaciado en pantallas pequeñas */
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #357abd; /* Azul más oscuro */
}

/* Diseño responsive */

/* Para pantallas pequeñas (teléfonos) */
@media (max-width: 600px) {
  h1 {
    font-size: 1.5rem;
  }

  .button {
    font-size: 14px; /* Reduce el tamaño del texto en botones */
    padding: 8px 16px; /* Reduce el padding para botones más pequeños */
  }

  .button-container {
    flex-direction: column; /* Botones apilados verticalmente en pantallas pequeñas */
  }
}

/* Para pantallas medianas (tabletas) */
@media (min-width: 601px) and (max-width: 1200px) {
  h1 {
    font-size: 1.75rem;
  }

  .button {
    font-size: 15px;
    padding: 9px 18px;
  }

  .button-container {
    flex-direction: row; /* Botones en fila para pantallas medianas */
  }
}