.selector {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  gap: 15px;
}

.selector-button {
  padding: 12px 25px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 25px; /* Botones redondeados */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra sutil */
  cursor: pointer;
  transition: all 0.3s ease; /* Suaviza la transición para hover y active */
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase; /* Texto en mayúsculas */
}

.selector-button.active {
  background-color: #0056b3; /* Color más oscuro para el botón activo */
  transform: translateY(-2px); /* Efecto de levantamiento */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2); /* Aumenta la sombra en el botón activo */
}

.selector-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px); /* Efecto de levantamiento */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); /* Aumenta la sombra en hover */
}

.selector-button:focus {
  outline: none; /* Quita el borde por defecto al hacer foco */
}

.selector-button:active {
  background-color: #003a75; /* Color aún más oscuro al hacer clic */
  transform: translateY(0); /* Vuelve el botón a su posición original al hacer clic */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Reduce la sombra al hacer clic */
}

.content-container {
  margin-top: 20px;
}

.content {
  padding: 20px;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 5px;
}

/* styles.css */
.promo-list {
  list-style-type: none; /* Quita los puntos de la lista */
  padding: 0; /* Elimina el padding */
  margin: 0; /* Elimina el margin */
}

.promo-item {
  border: 1px solid #ddd; /* Agrega un borde */
  border-radius: 5px; /* Redondea las esquinas */
  padding: 10px; /* Espaciado interno */
  margin-bottom: 10px; /* Espaciado entre elementos */
  background-color: #f9f9f9; /* Color de fondo */
  display: flex; /* Utiliza Flexbox para alinear */
  justify-content: space-between; /* Espacio entre el nombre y la ganancia */
  align-items: center; /* Centra verticalmente */
}

.promo-name {
  font-weight: bold; /* Texto en negrita */
}

.promo-earning {
  font-size: 1.2em; /* Tamaño de fuente más grande */
  color: #007bff; /* Color del texto */
}
