.login-message {
    text-align: center;
    color: red;
    font-size: 4em;
}

.calculator-container {
    max-width: 600px;
    margin: 2% auto 0; 
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.calculator-container h1 {
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
}

.calculator-container p {
    color: #555;
    margin-bottom: 20px;
}

.input-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
}

.input-container select, 
.input-container input {
    padding: 10px;
    font-size: 1em;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-sizing: border-box;
}

.input-container select {
    background-color: #fff;
}

.result {
    padding: 20px;
    color: #fff;
    font-size: 1.5em;
    margin-top: 20px;
    border-radius: 5px;
}

.result.positive {
    background-color: #4caf50; /* Verde para resultados positivos */
}

.result.negative {
    background-color: #f44336; /* Rojo para resultados negativos */
}

.result p {
    margin: 10px 0;
}

.result p.benefit {
    font-size: 2em;
    font-weight: bold;
}

.small-copy-btn {
    font-size: 12px;
    padding: 2px 5px;
    float: right; /* Para alinearlo a la derecha */
    margin-left: 10px; /* Espacio entre el botón y el texto */
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 3px;
    cursor: pointer;
}

.small-copy-btn:hover {
    background-color: #e0e0e0;
}