.main-container {
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-top: 50px;
  }
  
  .content-columns {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin: 20px 0;
  }
  
  .column {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .column h2 {
    margin-top: 0;
  }
  
  .footer {
    text-align: center;
    margin-top: 40px;
    padding: 20px;
    background-color: #222;
    color: #fff;
    border-radius: 8px;
  }
  