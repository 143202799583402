.table-odds {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    font-family: Arial, sans-serif;
}

.table-odds th, .table-odds td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

.table-odds th {
    background-color: #f2f2f2;
    color: #333;
    font-weight: bold;
}

.table-odds tr:nth-child(even) {
    background-color: #f9f9f9;
}

.table-odds tr:hover {
    background-color: #f1f1f1;
}

.table-odds td {
    color: #555;
}

.main-container h1 {
    text-align: center;
    font-size: 24px;
    color: #444;
}