/* navbar.css */

.navbar-container {
  background-color: #1e2a38; /* Azul oscuro */
  display: flex;
  justify-content: space-between; /* Espacio entre los elementos, el botón estará a la derecha */
  align-items: center; /* Alinea verticalmente los elementos dentro del navbar */
  padding: 10px 20px; /* Añade un poco de padding para mayor comodidad */
  flex-wrap: wrap; /* Permite que los elementos se envuelvan si hay poco espacio */
}

.nav-items {
  display: flex; /* Alinea los elementos de navegación en línea */
}

a {
  color: #ffffff; /* Blanco */
  text-decoration: none; /* Quita el subrayado de los enlaces */
}

.nav-item {
  color: #ffffff; /* Blanco */
  text-align: center;
  padding: 14px 20px;
  text-decoration: none;
  font-size: 17px;
  transition: background-color 0.3s, color 0.3s;
  margin: 0 10px; /* Añade un margen entre los elementos para que no estén pegados */
}

.nav-item:hover {
  background-color: #4a90e2; /* Azul más claro en hover */
  color: #ffffff; /* Blanco */
  border-radius: 10px;
}

.login-button {
  background-color: #4CAF50; /* Verde */
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

.login-button:hover {
  background-color: #45a049; /* Verde más oscuro en hover */
}

.account-button{
  background-color: #9daf4c; /* Verde */
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

.account-button:hover{
  background-color: #77843b;
}

/* Diseño responsive */

/* Para pantallas pequeñas (teléfonos) */
@media (max-width: 600px) {
  .navbar-container {
    flex-direction: column; /* Los elementos se apilan verticalmente */
  }

  .nav-items {
    flex-direction: column; /* Los elementos de navegación se apilan verticalmente */
    width: 100%; /* Asegura que ocupe todo el ancho */
    margin-bottom: 10px; /* Añade espacio entre la navegación y el botón */
  }

  .nav-item {
    padding: 12px 0; /* Ajusta el padding para botones más compactos */
    font-size: 15px; /* Reduce el tamaño del texto */
    margin: 5px 0; /* Ajusta el margen para que los elementos no estén demasiado separados */
  }

  .login-button {
    width: 100%; /* Hace que el botón ocupe todo el ancho disponible */
    margin-top: 10px; /* Añade espacio superior */
  }
}

/* Para pantallas medianas (tabletas) */
@media (min-width: 601px) and (max-width: 1200px) {
  .nav-item {
    padding: 12px 15px; /* Ajusta el padding para pantallas medianas */
    font-size: 16px; /* Reduce ligeramente el tamaño del texto */
    margin: 0 5px; /* Ajusta el margen */
  }

  .login-button {
    font-size: 16px; /* Ajusta el tamaño del texto del botón */
  }
}