.promo-container {
  width: 100%;
  margin: 0 auto; /* Centra el contenedor en la pantalla */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Sombra suave para darle profundidad */
  background-color: #fff; /* Fondo blanco para un look limpio */
  border-radius: 10px; /* Bordes redondeados para un toque moderno */
  box-sizing: border-box; /* Incluye padding en el cálculo del ancho total */
  justify-content: center;
}

.promo-bookie-image {
  width: 150px; /* Aumenta el tamaño de la imagen */
  height: auto;
  display: block;
  margin: 0 auto 20px auto; /* Centra la imagen y añade un margen inferior */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave para la imagen */
}

p {
  font-size: 1.1rem;
  line-height: 1.6; /* Aumenta el interlineado para mejorar la legibilidad */
  color: #555; /* Color de texto más suave para el cuerpo del texto */
  margin-bottom: 15px; /* Añade espacio entre los párrafos */
  text-align: center; /* Centra el texto dentro de su contenedor */
}

#promoContainer{
  display: flex;
  margin-top: 20%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}

.description-container {
  align-content: center;
  align-items: center;
  justify-content: space-around;
  margin: 20px 0;
}