/* account.css */

/* Estilos generales para la página de cuenta */
.main-container-account {
    max-width: 50%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-top: 50px;
}

h2 {
    text-align: center;
    color: #333;
    margin-bottom: 30px;
    font-size: 28px;
    font-weight: bold;
}

/* Estilos para la información de la cuenta */
.account-info {
    margin-bottom: 20px;
}

.account-info p {
    font-size: 18px;
    color: #555;
    margin: 10px 0;
}

.account-info p strong {
    color: #333;
}

/* Estilos para los botones */
button {
    display: block;
    width: 100%;
    padding: 10px 0;
    margin: 10px 0;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background-color: #4CAF50;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #45a049;
}

.logout-button {
    background-color: #f44336;
}

.logout-button:hover {
    background-color: #d32f2f;
}

.delete-account-button {
    background-color: #2196F3;
}

.delete-account-button:hover {
    background-color: #1976D2;
}

/* Estilos responsivos */
@media (max-width: 768px) {
    .main-container {
        padding: 15px;
        margin-top: 30px;
    }

    h2 {
        font-size: 24px;
    }

    .account-info p {
        font-size: 16px;
    }

    button {
        font-size: 14px;
    }
}